.modal-title {
    padding: 20px 10px;

}

#heading {

    padding-right: 250px;
}

#ResetPassword {
    position: relative;
    top: 5px;
}

/* .iconContainer {

    position: absolute;

    top: 30%;
    right: 15px;
  

} */

#reset{
    margin-left: 167px;
    margin-top: 10px;

}
/* .FullName{
font-size: larger;
} */
