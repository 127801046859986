.meeting.wrapper {
  /* background: rgb(12, 43, 64); */
  background: black;;
  /* height: 61%; */
}

video {
  max-width: unset;
}

.meeting .ag-header {
  padding: 20px 30px;
  /* display: flex; */
  display: none;
  justify-content: space-between;
  align-items: center;
}

.ag-header-lead {
  font-size: 16px;
  line-height: 35px;
}

.meeting .ag-footer {
  /* padding: 0 30px; */
  padding: 15px 70px;
  /* display: flex; */
  display: none;
  align-items: center;
}

.meeting .ag-main {
  position: relative;
}

.meeting .ag-footer :first-of-type {
  margin-right: 80px;
}

.header-logo {
  width: 60px;
  height: 35px;
  margin-right: 12px;
}

.ag-container {
  /* width: calc(100% - 60px);
  height: 100%; */
  margin: 0 auto;
  /* background: rgba(255, 255, 255, 0.1); */
  border-radius: 12px;
  width: 100% ;
  background: black;
  /* border-radius: 12px; */
  height: 600px;
}
