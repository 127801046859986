.top-left {
  position: absolute;
  cursor: nwse-resize;
  height: 10px;
  width: 10px;
  z-index: 2;
  left: 0;
  top: 0;
}

.top {
  position: absolute;
  cursor: ns-resize;
  height: 4px;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
}

.top-right {
  position: absolute;
  cursor: nesw-resize;
  height: 10px;
  width: 10px;
  z-index: 2;
  right: 0;
  top: 0;
}

.right {
  position: absolute;
  cursor: ew-resize;
  width: 4px;
  height: 100%;
  z-index: 1;
  right: 0;
  top: 0;
}

.bottom-left {
  position: absolute;
  cursor: nesw-resize;
  width: 10px;
  height: 10px;
  z-index: 2;
  left: 0;
  bottom: 0;
}

.bottom {
  position: absolute;
  cursor: ns-resize;
  width: 100%;
  height: 4px;
  z-index: 1;
  bottom: 0;
  left: 0;
}

.right-bottom {
  position: absolute;
  cursor: nwse-resize;
  width: 10px;
  height: 10px;
  z-index: 2;
  right: 0;
  bottom: 0;
}

.left {
  position: absolute;
  cursor: ew-resize;
  width: 4px;
  height: 100%;
  z-index: 1;
  left: 0;
  top: 0;
}
