.overlay1 {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.1);

  z-index: 9999;
}
.popup1 {
  /* border-color: black; */
  align-items: center;
  /* background: rgba(0, 0, 0, 0.1); */
  justify-content: center;
  border-radius: 5px;
  width: 80%;
  cursor: default;
  position: absolute;
  margin-top: 24px;
  left: 5%;
}
.popup_inner1 {
  padding: 10px;
  padding-top: 1px;
  border-radius: 5px;
  background: white;
  position: absolute;
  width: 146px;
 
}
.popup1 .content {
  max-height: 30%;
}
