/* Style the accordion section */
.accordion__section {
  display: block;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  /* padding-left: 10px !important; */
}

.accordion p {
  padding: 0;

  margin: 0;
  margin-top: auto;
  margin-bottom: 0;
  margin-right: 10px;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {}

/* Style the accordion content title */
.accordion__title {
  font-weight: 500;
  font-size: 12px;
  color: var(--deepBlue);
  padding-left: 10px;
}

/* Style the accordion chevron icon */
.accordion__icon {
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  overflow: auto;
  transition: max-height 0.6s ease;
  border-bottom: 1px solid var(--greyBorder);
  /* padding-left:25px; */
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.accordion_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2px 0;
  padding: 3px 0;
  border: 1px solid transparent;
  padding-right: 10px;
}

.bg {
  background: red;
}

.time {
  font-weight: 500;
  font-size: 10px;
  color: var(--middleGrey);
}

li .accordion__section {
  padding: 0 !important;
  margin: 0;
}

.accordion__content::-webkit-scrollbar {
  display: none;
}

/* Style the accordion content text */
.accordion__text {
  font-weight: 500;
  font-size: 12px;
  color: var(--darkGreen);
}

.accordion__content ul li {
  /* background: red; */
}

li .accordion__content ul li {
  /* background: blue; */
}

.accordion__content ul li .title {
  padding-left: 14px;
}

li .accordion__content ul li .title {
  padding-left: 34px;
}

.accordion {
  padding: 4px 0;
  /* padding-left: 18px; */
}

li .accordion {
  padding: 4px 0;
  padding-left: 37px;
}

/* .accordion_item:hover{
        border:1px solid var(--lightGreen);
        background: var(--white);
        border-radius: 15px;
       
        
   } */
.accordion_item_container {
  border: 1px solid transparent;
}

.accordion_item_container img {
  display: none;
}

.accordion_item_container:hover img {
  display: block;
}

.accordion_item_container:hover {
  border: 1px solid var(--lightGreen);
  background: var(--white);
  border-radius: 15px;
  color: var(--deepBlue);
}

.item_active img {
  display: block;
}

.item_active {
  border: 1px solid var(--lightGreen);
  background: var(--white);
  border-radius: 15px;
  color: var(--deepBlue);
}