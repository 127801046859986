@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@450&display=swap');

:root {

  --middleGreen: #9EBE21;
  --darkGreen: #728F01;
  --lightGreen: #CFDFAE;
  --deepBlue: #38454F;

  --seaBlue: #526666;
  --greyBlue: #798E8F;
  --greyBlueLight: #798E8F16;
  --greyBlue30: #798E8F4D;
  --darkGrey: #3A3A3A;
  --mediumGrey: #898989;
  --grey20: #c2c2c2;
  --lightGrey: #e2e2e2;
  --grey5: #efefef;
  --white: #ffffff;
  --black: #000000;
  --middleGrey: #A6A6A6;
  --greyBorder: #F0F0F0;
  --grey: #E4E8E9;
  --lightWhite: #F7F7F7;
  --lightWhite50: #F7F7F780;
  --midWhite: #F2F4F4;
  --scrollbarColor: #C4C4C4;
  --sliderColor: #161616;
  --sliderThumbColor: #A5A5A5;
  --notebackground: #454545;
  --highlightGrey: #B7C2C3;


  --yellow: #F8E32A;
  --darkYellow: #F1C93B;
  --mediumYellow: #FBAA0D;
  --deepPurple: #664078;
  --darkPurple50: #BA80D680;
  --limeGreen: #B3DB1A;
  --lightRed: #FF8B8B;

  --yellow: #f8e32a;
  --deepPurple: #664078;
  --limeGreen: #b3db1a;

  --snapGreen: #83A600;
  --sapGreen: #728F01;

  --darkWhite: #E1E5E5;

  --primaryColor: var(--middleGreen);
  --secondaryColor: var(--deepBlue);
  --ternaryColor: var(--seaBlue);
  --quadColor: var(--greyBlue);

  --adminBackground: var(--grey5);

  --headingColor: var(--ternaryColor);

  --borderColor: var(--grey5);
  --darkBorderColor: var(--lightGrey);

  --textColor: var(--quadColor);
  --paraColor: var(--darkGrey);

  --sideNavColor: var(--grey20);
  --navActive: var(--quadColor);

  --labelColor: var(--quadColor);
  --inputColor: var(--quadColor);
  --errorColor: var(--red);

  --progressColor: var(--deepPurple);

  --borderRadius: 1.5rem;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  display: inline-block;
}

a.underline:hover p {
  text-decoration: underline;
  border-color: inherit;
}

a.line-button:hover button {
  font-size: 0.875rem;
}

a:hover {
  text-decoration: none;
}

p {
  color: var(--paraColor);
}

.card-border {
  border: 1px solid #efefef !important;
}

.col-md-5percent {
  max-width: 5% !important;
}

.col-md-10percent {
  max-width: 10% !important;
}

.col-md-12percent {
  max-width: 12% !important;
}

.col-md-22 {
  max-width: 22% !important;
}

.col-md-70 {
  max-width: 70% !important;
}

.col-md-80 {
  width: 90% !important;
}

.fit-content {
  max-width: fit-content !important;
}

.lineheight-18 {
  line-height: 18px;
}

.textalign {
  text-align: center;
}

.card-border {
  border: 1px solid #e2e2e2 !important;
}

.modern-radio-container {
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
}

.radio-outer-circle {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border: 2px solid var(--middleGreen);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  transition: all 0.1s linear;
}

.radio-inner-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--middleGreen);
  transition: all 0.1s linear;
}

.unselected {
  border: 2px solid var(--greyBlue);
}

.unselected-circle {
  width: 0;
  height: 0;
}

.helper-text {
  color: var(--greyBlue) !important;
  padding-right: 8px;
}

.helper-text-selected {
  color: var(--middleGreen) !important;
  padding-right: 8px;
}

/* vertical divider between columns */
.row.vertical-divider {
  overflow: hidden;
}

.row.vertical-divider>div[class^="col-"] {
  text-align: center;
  padding-bottom: 100px;
  margin-bottom: -100px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.row.vertical-divider div[class^="col-"]:first-of-type {
  border-left: none;
}

.row.vertical-divider div[class^="col-"]:last-child {
  border-right: none;
}

/* The Select box container */
.CheckContainer {
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-left: 1rem;
}

/* Hide the browser's default checkbox */
.CheckContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 20px;
}

/* On mouse-over, add a grey background color */
.CheckContainer:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.CheckContainer input:checked~.checkmark {
  background-color: #898989;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.CheckContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.CheckContainer .checkmark:after {
  left: 8px;
  top: 3px;
  width: 7px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.fetching {
  display: flex;
  justify-content: center;
  font-size: 30px;
  padding: 20px 0;
  font-weight: 200;
}

/* vertical divider between columns ends here */
/* @media (max-width: 1440px) {
  html {
    font-size: 14px;
  }
} */

.criteria-group .divider {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.md-btn {
  width: 120px;
}

.underline-text {
  text-decoration: underline;
}

.cursor {
  cursor: pointer;
}

.lineheight-105 {
  line-height: 1.5;
}

.absolute-position {
  position: absolute !important;
}

.relative-position {
  position: relative !important;
}

.image-modal {
  top: 10%;
  width: 612px !important;
}

.image-left-arrow {
  left: -10px;
  top: 35%;
}

.image-right-arrow {
  right: -10px;
  top: 35%;
}

.box-shadow-none {
  box-shadow: none !important;
}

.icon-input-style {
  font-size: 20px;
  position: absolute;
  color: #c2c2c2 !important;
  font-weight: initial;
  right: 0;
  bottom: 0;
}

.icon-input-style-left {
  font-size: 20px;
  position: absolute;
  color: #c2c2c2 !important;
  font-weight: initial;
  left: 0;
  bottom: 0;
}

/* slider */
.slider {
  -webkit-appearance: none;
  appearance: none;
  background: var(--seaBlue);
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  padding-right: 0.25rem;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  background: var(--seaBlue);
  cursor: pointer;
  border-radius: 5px;
  border: 0.18em solid var(--white);
}

.slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  background: var(--seaBlue);
  cursor: pointer;
}

.weeklyCalendar .fc .fc-timegrid-event-harness {
  max-width: 100%;
}

.reassginGrpMsg {
  font-family: 'Noto Sans' !important;
  color: #38454F !important;
  font-size: 12px;
  font-weight: 510;
}

.css-1hsgu7t {
  width: 25px;
}

.navbar-brand{
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.navbar-brand img {
 height: 70px !important;
}

.dropdownIcon {
  border-radius: 4px;
  color: var(--middleGreen);

}

.dropdownIcon-button {
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .dropdownIcon-button img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
} */

.dropdownIcon-menu {
  position: absolute;
  top: 100%;
  left: calc(100% - 454px);
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 180px;
  z-index: 100;
  border-radius: 5px;
  max-height: 200px; /* Set the maximum height for the dropdown */
  overflow-y: auto;/* Add vertical scrolling if content exceeds max height */
  font-size: 12px;
  font-weight: bold;
 

}

.dropdownIcon-menu-index {
  position: absolute;
  top: 62%;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 180px;
  z-index: 100;
  border-radius: 5px;
  max-height: 200px; /* Set the maximum height for the dropdown */
  overflow-y: auto;/* Add vertical scrolling if content exceeds max height */
  font-size: 12px;
  font-weight: bold;
  margin-top:  3%;
}

.dropdownIcon-item {
  padding: 5px;
  cursor: pointer;
  
}

.dropdownIcon-item:hover {
  background-color: #f5f5f5;
}
.defaultItem{
  cursor: not-allowed;
  color:  #38454F;  
}
.defaultItem:hover{
  cursor: not-allowed;
  color:  #38454F;  
  background-color: transparent;
}

.selectItemDropdown{
  background-color: #F0F0F0;
}