.admin {
  background: var(--white);
  min-height: 100vh;
  padding: 7rem 4rem 4rem 14.6rem;
}

@media(max-width: 1366px) {
  .admin {
    padding: 7rem 4rem 4rem 13rem;
  }
}

.card-height {
  height: 536.26px;
}

.svg-white {
  filter: brightness(0) invert(1);
}

.fc .fc-timegrid-slot-minor {
  border-top-style: dashed !important;
  border-top-color: #E2E2E2 !important;
}

.dayCalendar .fc-scrollgrid-section-header .fc-scroller {
  background: var(--greyBlueLight);
}


.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 14px;
  margin-top: 5px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(114, 143, 1, 0.17);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: -2px;
  bottom: 0px;
  background-color: #878787;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background: rgba(114, 143, 1, 0.17);
}

input:focus+.slider {
  box-shadow: rgba(114, 143, 1, 0.17);
}

input:checked+.slider::before {
  background-color: #A4C916;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(15px);
}



.team {
  width: 200px;
  height: 100px;
  position: relative;
  /* margin: 10px; */
}

.card1,
.cardOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.cardOverlay {
  z-index: 10;
}

.pt-2 {
  padding-left: 11px;
}

.mrg {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1800px) {
  .project_image{
    width: 150px;
  }
  .delete_button {
    position: relative;
    padding-left: 115px;
  }

  .view_download_button {
    padding-left: 12%;
    display: flex;
    margin-top: 8%;
  }

  .permission {
    padding-left: 25px !important;
  }

  .permission-toggle {
    margin-left: 40px !important;
  }

  .save_button_pool{
    margin-left: 80px;
  }
}

.myTooltip > div{
  width: 180px;
}