#ag-canvas {
  height: 100%;
}

.ag-item :first-of-type {
  /* border-radius: 6px; */
}

.ag-item {
  border-radius: 6px;
  /* border: 2px #00B6ED solid; */
  background: url('../../assets/images/avatar.png') center no-repeat;
  height: 100%;
  width: 100%;
  object-fit: contain !important;
  /*min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
}

#ag-resize-container {
  background-image: none!important;
  background-color: black;
  display: flex;
  justify-content: center;
  height: center;
}

#ag-resize-container .ag-item {
  border: none!important;
}


/* button group */

/* .ag-btn-group {
  height: 70px;
  height: 50px;
  width: calc(100% - 60px);
  margin: 0 auto;
  position: absolute;
  bottom: 0;
  background: transparent;
  z-index: 11;
  display: flex;
  justify-content: space-around;
  align-items: center;
} */

/*new ag-btn-group css for new deisgn of interview window*/

.ag-btn-group {
  height: 45px;
  width: 100%;
  margin: 0 auto;
  position: absolute;
  top: 0%;
  background: #000;
  z-index: 12;
  display: flex;
  justify-content: flex-end;
  align-items: center;

}

.ag-btn-group:hover,
.ag-btn-group.active {
  /* background: rgba(18, 74, 99, .8); */
  background: rgba(21, 21, 21, 1);
}

.ag-btn {
  z-index: 12;
  opacity: 0;
  color: white;
  /* width: 35px;
    height: 35px; */
  cursor: pointer;
  /* font-size: 60px; */
  font-size: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ag-select{
  z-index: 12;
  opacity: 0;
  color: white;
  /* width: 35px;
    height: 35px; */
  cursor: pointer;
  /* font-size: 60px; */
  font-size: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ag-btn:hover {
  color: #00B6ED;
}

.ag-btn.disabled,
.ag-btn.disabled:hover {
  color: #637C8E;
  cursor: not-allowed;
}

.ag-btn-group:hover .ag-btn,
.ag-btn-group.active .ag-btn {
  opacity: 1;
}

.ag-btn-group:hover .ag-select,
.ag-btn-group.active .ag-select {
  opacity: 1;
}

.ag-btn.off.videoControlBtn .ag-icon-camera {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera-off {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera {
  display: inline-block;
}

.ag-btn.off.videoControlBtn .ag-icon-camera-off {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic-off {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic-off {
  display: inline-block;
}
.captionBox{
  min-height: 77px;
  overflow-y: auto;
  z-index: 1000;
}

.captionBox::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  border-radius: 10px;
}
.captionBox::-webkit-scrollbar {
  width: 5px;
}
/* Handle */
.captionBox::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #C4C4C4; 
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
.captionBox::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 5px; 
}
.respondentBox{
  position: absolute;
    width: 96px;
    height: 146px;
    top: 4px;
    z-index: 1000;
    left: 28px;
}
.researcherBox{
  position: absolute;
    width: 96px;
    height: 146px;
    top: 4px;
    z-index: 3;
    left: 28px;
}