.Toastify__toast-container.custom-toast-container {
  position: absolute;
  top: 50px;
  max-width: 60.333333%;
  overflow-x: hidden; 
}

.Toastify__toast.custom-toast-body {
  min-height: 50px;
  
  max-height: 80px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.Toastify__close-button.transparent-close-button {
  color: transparent !important;
}

.Toastify__toast.transparent-toast-body {
  background-color: transparent !important;
  box-shadow: none !important;
}

.Toastify__toast.custom-toast-body {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.Toastify__toast--success {
  background-color: 'red';
}



.Toastify__toast--error.custom-toast-body {
  text-align: left;
}
