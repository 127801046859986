.panel {
/*    
 top: 106px;
  left: 106px;
  width: 200px;*/
  
 /* background:red;
  border:1px solid white;
  width: 10px;
height:50px; */


  box-sizing: border-box;
 
}

.panel__container {
  height: 100%;
  position: relative;
}

