/* Custom properties */
:root {
  --tooltip-text-color: white;
  --tooltip-background-color: black;
  --tooltip-margin: 30px;
  --tooltip-arrow-size: 6px;
}

/* Wrapping */
.Tooltip-Wrapper {
  display: inline-block;
  position: relative;
}
/* Absolute positioning */
.Tool-Tip {
 position: absolute;
 border-radius: 4px;
 left: 0;
 transform: translateX(-50%);
 padding: 6px;
 color: var(--tooltip-text-color);

 font-size: 14px;
 font-family: sans-serif;
 line-height: 1;
 z-index: 100;
 white-space: nowrap;
}

/* CSS border triangles */
.Tool-Tip::before {
 content: " ";
 left: 50%;
 border: solid transparent;
 height: 0;
 width: 0;
 position: absolute;
 pointer-events: none;
 border-width: var(--tooltip-arrow-size);
 margin-left: calc(var(--tooltip-arrow-size) * -1);
}
/* Absolute positioning */
.Tool-Tip.direction-top {
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tool-Tip.direction-top::before {
  /* top: 100%;
  border-top-color: var(--tooltip-background-color); */
}

/* Absolute positioning */
.Tool-Tip.direction-right {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tool-Tip.direction-right::before {
  /* left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color); */
}

/* Absolute positioning */
.Tool-Tip.direction-bottom {
  left: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(-70%) translateY(35%);
}
/* CSS border triangles */
.Tool-Tip.direction-bottom::before {
  /* bottom: 100%;
  border-bottom-color: var(--tooltip-background-color); */
}

/* Absolute positioning */
.Tool-Tip.direction-left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tool-Tip.direction-left::before {
  /* left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color); */
}

