

  
.overlay2{
    visibility:hidden;
    opacity: 0;
    position: fixed;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    background: rgba(0,0,0,0.1);
   
    z-index: 9999;
    
  }
  .popup2{
    border-color: black;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    justify-content: center;
    border-radius: 5px;
    width: 50%;
    cursor:default;
    position: fixed;
    
  }
  .popup_inner2{
    padding: 15px;
    background:#fff;
    border-radius: 5px;
    width: 30%;
    background:white;
    position: fixed;
  }
  .popup2 .content{
    max-height: 30%;
  }